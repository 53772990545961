/* eslint-disable @typescript-eslint/no-explicit-any */
// Account - Favourites Page -  Auth Page

import React from 'react';
import { GetServerSideProps } from 'next';
import { ApolloProvider } from '@apollo/client';
import { inspect } from 'util';

import { ResponsiveGrid } from '@adobe/aem-react-editable-components';

import { respGridUtil, getProcessEnvs, prePageCall, Layout, logger } from '@marriott/mi-headless-utils';

import { getDeployedEnvType, useMiApolloClient } from '@marriott/mi-apollo-client-utils';

import { constants } from '@marriott/mi-account-components/constants';

import { preProcessorCall, getLocaleFromParams } from '@marriott/mi-account-components/utils';

import { signInModel } from '../../_localDevMocks';
import operationSignatures from '../../_constants/operationSignatures.json';

const { NEXT_PUBLIC_AEM_PATH, SKIP_PREPROCESSOR_CALL, IS_LOCAL_DEV } = getProcessEnvs();
const { ITEM_PATH_ROOT, UNAUTH_URL, NEXT_PUBLIC_ENV_KEYS } = constants;

global.loggerInstance = logger({}) as CallableFunction;
const { log } = global.loggerInstance('SignInPage');

export default function SignInPage({ model, deployedEnvType }) {
  const MiApolloClient = useMiApolloClient(operationSignatures, deployedEnvType);

  const {
    metaNames,
    metaProperties,
    title,
    staticComponentClientLibsCSS,
    staticComponentClientLibsJS,
    gridCss,
    authorMode,
    canonical,
  } = model;
  const responsiveGridModel = respGridUtil(model?.cqItems?.root?.[':items']?.responsivegrid);

  return (
    <ApolloProvider client={MiApolloClient}>
      <Layout
        metaNames={metaNames}
        metaProps={metaProperties}
        title={title}
        staticComponentClientLibsCSS={staticComponentClientLibsCSS}
        staticComponentClientLibsJS={staticComponentClientLibsJS}
        gridCss={gridCss}
        isAuthorMode={authorMode}
        canonical={canonical}
      >
        <ResponsiveGrid
          {...responsiveGridModel}
          model={responsiveGridModel}
          pagePath={NEXT_PUBLIC_AEM_PATH}
          itemPath={ITEM_PATH_ROOT}
          customClassName="account-page-wrapper"
        />
      </Layout>
    </ApolloProvider>
  );
}

//Any product specific page data apart from common data like session, datalayer
//can be returned from this function and the same is passed as props to the page Component
const EAASignInPageSpecificData = async request => {
  const deployedEnvType = getDeployedEnvType();
  let response: any = { operationSignatures: [], deployedEnvType, isEAA: true };
  const resolvedUrlString: string = request?.req?.url;
  const isAuthorMode = resolvedUrlString?.includes('isAuthorMode');
  const urlQueryParameters = new URLSearchParams(request?.query)?.toString();
  const preprocessorPath = urlQueryParameters ? `${UNAUTH_URL}?${urlQueryParameters}` : UNAUTH_URL;

  /**
   * Preprocessor Call Start
   */

  // check if locale is present as url Param and assign it if it is found.
  let currentLocale;
  if (resolvedUrlString?.includes('locale=')) {
    currentLocale = getLocaleFromParams(resolvedUrlString);
  }

  /** Bypassing preprocessor call intentionally for testing. It should be reverted back */
  if (SKIP_PREPROCESSOR_CALL !== 'true' && IS_LOCAL_DEV !== 'true' && !isAuthorMode) {
    // Making page specific preprocessor call
    log.debug(`[EAASignInPageSpecificData] session Id: ${request?.req?.cookies?.sessionID}`);
    const customHeaders = { isEAA: request?.req?.headers?.iseaa ?? '' };
    const preprocessorResponse = await preProcessorCall(
      request?.req?.cookies?.sessionID,
      request?.req,
      preprocessorPath,
      customHeaders,
      { isEncryptEnabled: true }
    );
    log.debug(`[EAASignInPageSpecificData] PreprocessorResponse: ${inspect(preprocessorResponse)}`);
    if (preprocessorResponse?.data?.error) {
      const error = {
        redirect: { redirect: preprocessorResponse?.data?.redirect },
        value: true,
      };
      response = { operationSignatures: [], deployedEnvType, error };
    } else if (preprocessorResponse?.data) {
      response = {
        operationSignatures: [],
        deployedEnvType,
        isEAA: preprocessorResponse?.data?.isEAA,
        rsaPublicKey: preprocessorResponse?.data?.rsaPublicKey,
        nextPublicEnvKeys: NEXT_PUBLIC_ENV_KEYS,
      };
      if (currentLocale) {
        response = { ...response, currentLocale: currentLocale };
      }
    }
  }
  /**
   * Preprocessor Call End
   */
  return response;
};

//Make pre page calls like session, datalayer, fetch model.json, producSpecifc data etc
//at server side and pass the data is passed as props to page component.
export const getServerSideProps: GetServerSideProps<any> = prePageCall(EAASignInPageSpecificData, signInModel);
